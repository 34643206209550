import {CheckOutlined, CloseOutlined} from "@mui/icons-material";
import React, {useContext} from "react";
import {OverflowTooltip} from "../util/util";
import Typography from "@mui/material/Typography";
import {FormControl, FormControlLabel, FormHelperText, MenuItem, Select, Switch} from "@mui/material";
import Input from "@mui/material/Input";
import {DatePicker, DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {EnumContext} from "./enumContext";

export function ContentField({field, alias, value}) {
    const {enums} = useContext(EnumContext);
    switch (field.type) {
        case "boolean":
            return (value ? <CheckOutlined key={`${alias}-true`} fontSize="large"/> :
                <CloseOutlined key={`${alias}-false`} fontSize="large"/>)
        case "text":
            return <OverflowTooltip followCursor key={`${alias}-value-tooltip`} title={value}>
                <Typography variant="body1">
                    {value}
                </Typography>
            </OverflowTooltip>
        case "enum":
            return <OverflowTooltip followCursor
                                    key={`${alias}-value-tooltip`}
                                    title={Object.keys(enums).includes(alias) ? enums[alias].data[value] : value}>
                {Array.isArray(value) ?
                    value.map((item) => <Typography variant="body1" key={`${alias}-${item}-value`}>
                        {enums[alias].data[item] || item}</Typography>) :
                    <Typography variant="body1" key={`${alias}-value`}>
                        {enums[alias].data[value] || value}</Typography>}
            </OverflowTooltip>
        case "int":
            return <OverflowTooltip followCursor
                                    key={`${alias}-value-tooltip`}
                                    title={value ? Number(value) : null}>
                <Typography variant="body1">
                    {value ? Number(value) : null}
                </Typography>
            </OverflowTooltip>
        case "float":
            return <OverflowTooltip followCursor
                                    key={`${alias}-value-tooltip`}
                                    title={value ? parseFloat(value) : null}>
                <Typography variant="body1">
                    {value ? parseFloat(value) : null}
                </Typography>
            </OverflowTooltip>
        default:
            return <OverflowTooltip followCursor key={`${alias}-value-tooltip`} title={value}>
                <Typography variant="body1">
                    {value}
                </Typography>
            </OverflowTooltip>
    }
}

export function ContentEditField({
    field,
    editedData,
    alias,
    error,
    onChange,
    onDateChange,
    onBoolChange,
    onEnumChange,
    regexCheck
}) {
    let regexp;
    const {enums} = useContext(EnumContext);

    switch (field.type) {
        case "boolean":
            return <FormControlLabel
                control={<Switch/>}
                name={alias}
                id={alias}
                key={`${alias}-bool-edit`}
                checked={editedData[alias]}
                onChange={onBoolChange}
                label=''
            />
        case "text":
            regexp = /.*/;
            if (field.regexPattern) {
                regexp = new RegExp(field.regexPattern);
            }
            return <FormControl key={`${alias}-text-edit`} sx={{minWidth: 250}}>
                <Input
                    value={editedData[alias] || ''}
                    onChange={(e) => onChange(e, regexp)}
                    name={alias}
                    error={(field.required && !editedData[alias]) || error || regexCheck[field.alias]}
                    id={alias}
                />
                <FormHelperText>{field.helperText}</FormHelperText>
            </FormControl>
        case "date":
            return <LocalizationProvider dateAdapter={AdapterDayjs} key={`${alias}-date-edit`}>
                <DatePicker
                    id={alias}
                    format='YYYY-MM-DD'
                    name={alias}
                    value={dayjs(editedData[alias])}
                    onChange={(value) => onDateChange(alias, value)}
                    slotProps={{textField: {variant: 'standard'}}}
                />
            </LocalizationProvider>
        case "datetime":
            return <LocalizationProvider dateAdapter={AdapterDayjs} key={`${alias}-datetime-edit`}>
                <DateTimePicker
                    fullWidth
                    id={field.alias}
                    name={field.alias}
                    label={field.display}
                    onChange={(value) => onDateChange(field.alias, value)}
                    defaultValue={dayjs(new Date())}
                    value={dayjs(editedData[alias])}
                    sx={{maxWidth: 350}}
                />
            </LocalizationProvider>
        case "enum":
            return <Select
                margin='normal'
                required
                multiple={field.multiple}
                name={alias}
                id={alias}
                key={`${alias}-enums-edit`}
                onChange={onEnumChange}
                value={editedData[alias]}
                variant='standard'
            >
                {Object.entries(enums[alias].data).map(([k, v]) => (
                    <MenuItem value={k} key={k}>{v}</MenuItem>
                ))}
            </Select>
        case "float":
            return <FormControl key={`${alias}-float-edit`} sx={{minWidth: 250}}>
                <Input
                    value={editedData[alias] || ''}
                    onChange={(e) => onChange(e, regexp)}
                    name={alias}
                    error={(field.required && !editedData[alias]) || error}
                    id={alias}
                />
                <FormHelperText>{field.helperText}</FormHelperText>
            </FormControl>
        case "int":
            return <FormControl key={`${alias}-int-edit`} sx={{minWidth: 250}}>
                <Input
                    value={editedData[alias] || ''}
                    onChange={(e) => onChange(e, regexp)}
                    name={alias}
                    type='number'
                    error={(field.required && !editedData[alias]) || error}
                    inputProps={{step: 1}}
                    id={alias}
                />
                <FormHelperText>{field.helperText}</FormHelperText>
            </FormControl>
        default:
            return <FormControl key={`${alias}-edit`} sx={{minWidth: 250}}>
                <Input
                    value={editedData[alias] || ''}
                    onChange={(e) => onChange(e)}
                    type='number'
                    name={alias}
                    error={(field.required && !editedData[alias]) || error}
                    id={alias}
                />
                <FormHelperText>{field.helperText}</FormHelperText>
            </FormControl>
    }
}