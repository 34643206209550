import {Alert, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {userFields} from "../util/modelFields";

const errorMessages = {
    '400': 'Missing submission item(s)',
    '403': 'Not allowed to add/modify object',
    '406': 'Data format error',
    '409': 'Failed to create/update instance',
    '410': 'Objects not found/Permission denied'
}

function aliasToDisplay(fields) {
    const fieldNames = Object.values(fields.fields).reduce((acc, item, _) => ({
        ...acc,
        [item.alias]: item.display
    }), {});
    const relationNames = Object.values(fields.relations).reduce((acc, item, _) => ({
        ...acc,
        [item.alias]: item.display
    }), {});
    return {...fieldNames, ...relationNames}
}

function buildErrorMessage(fields, resp, statusCode, batch, account) {
    if (typeof resp === 'string' || resp === null) {
        return resp;
    }
    if (batch) {
        const errorMessage = Object.entries(resp).map(([key, value]) => (`${errorMessages[key] || `Response Status ${key}`}: \n\n${Object.values(value).join('\n')}`));
        return errorMessage.join('\n');
    } else {
        let displayMap = aliasToDisplay(fields);
        displayMap = {...displayMap, non_field_errors: 'error', new_property: 'New Additional Information'};
        let updatedResp = {...resp};
        let propertyMessages = [];
        if (Object.keys(resp).includes('new_property')) {
            delete updatedResp.new_property;
            const propertyRows = resp.new_property.reduce((acc, item, index) => ({...acc, [index + 1]: item}), {});
            propertyMessages = Object.entries(propertyRows).filter(([_, item]) => Object.keys(item).length !== 0).map(([index, content]) => (`Config setting row ${index} ${Object.entries(content).map(([key, value]) => `${displayMap[key]}: ${value}`).join('\n')}`));
        }
        if (account) {
            const userMap = aliasToDisplay(userFields);
            let userMessages = [];
            if (Object.keys(resp).includes('a_user')) {
                delete updatedResp.a_user;
                userMessages = Object.entries(resp.a_user).map(([key, value]) => (`${userMap[key]}: ${value}`));
            }
            const fieldNames = Object.entries(updatedResp).map(([name, message]) => (`${displayMap[name]}: ${message}`));
            return `${errorMessages[statusCode] || `Response Status ${statusCode}`}, please check the fields below: \n\n${[...fieldNames, ...userMessages, ...propertyMessages].join('\n')}`
        } else {
            const fieldNames = Object.entries(updatedResp).map(([field, message]) => (`${displayMap[field]}: ${message}`));
            return `${errorMessages[statusCode] || `Response Status ${statusCode}`}, please check the fields below: \n\n${[...fieldNames, ...propertyMessages].join('\n')}`

        }
    }
}

export default function ErrorDisplay({alert, setAlert, fields, statusCode, response, batch, account}) {
    return <Collapse in={alert}>
        <Alert severity="error"
               action={
                   <IconButton
                       aria-label="close"
                       color="inherit"
                       size="small"
                       onClick={() => {
                           setAlert(false);
                       }}
                   >
                       <CloseIcon fontSize="inherit"/>
                   </IconButton>
               }
               sx={{whiteSpace: 'pre-line'}}
        >
            {buildErrorMessage(fields, response, statusCode, batch, account)}
        </Alert>
    </Collapse>

}