import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {routes, useDeleteData, useRefreshData} from "../routes/api";
import Cookies from "js-cookie";
import {sleep} from "../util/util";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';


export function ConfirmationDialog({open, handleClose, handleConfirm, message, keyword}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title">Confirm {keyword}</DialogTitle>
            <DialogContent
                sx={{
                    minWidth: '270px',
                    minHeight: '50px'
                }}
            >
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{
                paddingBottom: '16px',
                paddingRight: '16px'
            }}>
                <Button variant="outlined" onClick={handleConfirm} color="primary">
                    Confirm
                </Button>
                <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function DeleteComponent({alias, id, disabled, navigate, setError}) {
    const token = Cookies.get('token');
    const route = routes[alias];

    const {data: resp, statusCode, handleCall} = useDeleteData(token, route, id);
    const [openDialog, setOpenDialog] = useState(false);

    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = async () => {
        setOpenDialog(false);
        await handleCall();
    };

    useEffect(() => {
        if (statusCode >= 200 && statusCode < 300) {
            setError({error: false, message: '', statusCode: statusCode});
            navigate(`/${alias}`);
        } else if (statusCode > 300) {
            setError({error: true, message: resp, status: statusCode});
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [resp, statusCode]);

    return (
        <>
            <Button variant="outlined" color="error" onClick={handleDeleteClick} disabled={disabled}>
                Delete
            </Button>
            <ConfirmationDialog
                open={openDialog}
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                message='Are you sure to delete this item?'
                keyword='Delete'
            />
        </>
    );
}

export function FactoryResetComponent({id, disabled, setResetting, refresh, setError, addSnackbar}) {
    const token = Cookies.get('token');

    const {statusCode: resetStatus, handleCall: resetCall} = useRefreshData(token, routes.camera, id);
    const [openDialog, setOpenDialog] = useState(false);

    const handleResetClick = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleReset = async () => {
        try {
            setResetting(true);
            await resetCall({body: {type: 'FRS'}});
            if (resetStatus < 300) {
                if (addSnackbar) {
                    addSnackbar('Resetting', 'Unit', '3 minutes');
                }
                await sleep(4000);
                if (refresh) {
                    await refresh();
                }

            } else {
                setError(true);
            }
            setResetting(false);
        } catch (error) {
            console.error('Error resetting data:', error);
        }
    }

    const handleConfirmReset = async () => {
        setOpenDialog(false);
        await handleReset();
    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={handleResetClick}
                disabled={disabled}
                startIcon={<RotateLeftIcon/>}
            >
                Factory Reset
            </Button>
            <ConfirmationDialog
                open={openDialog}
                handleClose={handleClose}
                handleConfirm={handleConfirmReset}
                message='Are you sure to factory reset this unit?'
                keyword='Factory Reset'
            />
        </>
    );
}