import {Card, CardHeader, Grid, Modal, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";

import ReactLassoSelect from "react-lasso-select";
import Button from "@mui/material/Button";
import {routes, usePatchData} from "../routes/api";
import Cookies from "js-cookie";
import ErrorDisplay from "./errorDisplay";
import {cameraFields} from "../util/modelFields";

export function ImagePopUpModal({img, open, setOpen, id, currentPoints, addSnackbar}) {
    const token = Cookies.get('token');
    const route = routes.camera;
    const canCreate = Cookies.get('can_create');

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));


    const [points, setPoints] = useState(currentPoints || []);
    const [lassoClosed, setLassoClosed] = useState(false);
    const [alert, setAlert] = useState(false);

    const {
        data: patchResp,
        statusCode: patchStatus,
        handleCall: handlePatch
    } = usePatchData(token, route, id);

    const handleClose = () => {
        setOpen(false);
        setLassoClosed(false);
        setPoints(currentPoints);
    }
    const handleSubmit = () => {
        handlePatch({body: {c_coordinates: points}});
    }

    useEffect(() => {
        if (patchStatus >= 200 && patchStatus < 300) {
            if (addSnackbar) {
                addSnackbar('Updating ROI', 'Image', '2 minutes');
            }
            setAlert(false);
            setOpen(false);
            setLassoClosed(false);
        } else if (patchStatus > 300) {
            setAlert(true);
        }
    }, [patchStatus, patchResp]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '95vh',
                width: isSmallScreen ? '85vw' : '63vw',
                height: 'auto',
                overflow: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                padding: 1,
                borderRadius: '16px'
            }}>
                <CardHeader title="Select ROI"/>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <ErrorDisplay
                            alert={alert}
                            setAlert={setAlert}
                            response={patchResp}
                            fields={cameraFields}
                            statusCode={patchStatus}
                        />
                    </Grid>
                    <Grid item xs={10} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <ReactLassoSelect
                            value={points}
                            src={img}
                            disabled={!canCreate}
                            onChange={(value) => setPoints(value)}
                            onComplete={() => setLassoClosed(true)}
                            style={{width: "100%", maxWidth: "1200px"}}
                            imageStyle={{width: "100%", maxWidth: "1200px"}}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        {canCreate &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{mt: 2, mb: 2}}
                                disabled={!lassoClosed}
                            >
                                Submit
                            </Button>}
                    </Grid>
                </Grid>
            </Card>
        </Modal>
    )
}