import '../App.css';
import "leaflet/dist/leaflet.css";

import {MapContainer, Marker, TileLayer, Tooltip} from "react-leaflet";
import {Icon} from "leaflet";
import React from "react";
import {Typography} from "@mui/material";


const customIcon = new Icon({
    iconUrl: require("../assets/icon/pin.png"),
    iconSize: [38, 38]
});

function getLocation(unit) {
    return [parseFloat(unit.u_latitude), parseFloat(unit.u_longitude)]
}

export function PositionMap({units, className, handleClick, setMapView}) {
    const filteredUnits = units.filter((unit) => (unit.u_latitude && unit.u_longitude));

    if (filteredUnits.length === 0) {
        setMapView(false);
        return;
    }

    return (
        <MapContainer
            className={className || "leaflet-container"}
            center={getLocation(filteredUnits[0])}
            zoom={className === "leaflet-container-mini" ? 13 : 4}
            minZoom={2}
            scrollWheelZoom
            attributionControl={false}
            sx={{width: '100%', height: '100%'}}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {
                filteredUnits.map((unit) => (
                    <Marker position={getLocation(unit)}
                            icon={customIcon}
                            eventHandlers={{click: () => handleClick(unit)}}
                            key={`marker_${unit.u_sn}`}
                    >
                        <Tooltip>
                            <Typography variant='body2'>Unit {unit.u_sn}</Typography>
                            Unit Status: {unit.u_status}
                            <br/>
                            Camera Status: {unit.u_cam_error ? 'Camera Error' : 'All Camera Functional'}
                            <br/>
                            Wifi Status: {unit.u_wifi ? 'Online' : 'Offline'}
                        </Tooltip>
                    </Marker>
                ))
            }

        </MapContainer>
    )
}