import {Typography} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import addNewUnit from "../assets/FAQ/add-new-unit.png";
import addRelatedModel from "../assets/FAQ/add-related-model.png";
import cameraDetail from "../assets/FAQ/camera-detail.png";
import firmwareUpdate from "../assets/FAQ/firmware-update.png";
import modelUpdate from "../assets/FAQ/model-update.png";
import addNewCamera from "../assets/FAQ/add-new-camera.png";
import selectRelatedModel from "../assets/FAQ/select-related-model.png";
import switchToListView from "../assets/FAQ/switch-to-list-view.png";
import unitDetail from "../assets/FAQ/unit-detail.png";

export const FAQList = [
    {
        question: <Typography variant='h6'>1 - What is a unit?</Typography>,
        answer: <Typography variant='body1' gutterBottom>A "unit" is a generic name of product that can be managed from
            the Leopard Imaging Device Manager platform. In general, a unit has one or two cameras.</Typography>,
    },
    {
        question: <Typography variant='h6'>2 - How many cameras can be attached to a unit?</Typography>,
        answer: <Typography variant='body1' gutterBottom>A unit usually has one or two cameras. Users can choose the
            quantity of camera according to deployment requirements.</Typography>,
    },
    {
        question: <Typography variant='h6'>3 - What is a firmware?</Typography>,
        answer: <Typography variant='body1' gutterBottom>Firmware is a specific type of software that is embedded in
            hardware devices. Unlike general software applications that run on computers and can be easily updated or
            changed, firmware is closely tied to the hardware it controls. Our GS500 AI Sensing system relies on
            firmware built in its memory to function smoothly and efficiently. </Typography>,
    },
    {
        question: <Typography variant='h6'>4 - Why do we need to update the firmware?</Typography>,
        answer: <Typography variant='body1' gutterBottom>Although firmwares are not updated as frequently as regular
            software, we will be releasing new versions to fix bugs, add features, or improve
            performance. </Typography>,
    },
    {
        question: <Typography variant='h6'>5 - How to update firmware for multiple units?</Typography>,
        answer: <>
            <Typography variant='body1' gutterBottom>Go to <span style={{fontWeight: 'bold'}}>Units</span>. Go to
                listview by clicking <span style={{fontWeight: 'bold'}}>SWITCH TO LISTVIEW</span> if you are currently
                in map view.</Typography>
            <img src={switchToListView} alt='switch-to-list-view' style={{width: '70%', margin: 8}}/>
            <Typography variant='body1' gutterBottom>Select the units you would like to update, and click
                <span style={{fontWeight: 'bold'}}>FIRMWARE UPDATE</span> in the top left.</Typography>
            <img src={firmwareUpdate} alt='firmware-update' style={{width: '70%', margin: 8}}/>
        </>,
    },
    {
        question: <Typography variant='h6'>6 - What is an AI model?</Typography>,
        answer: <Typography variant='body1' gutterBottom>An AI model is a mathematical framework or algorithm designed
            to perform specific tasks such as traffic detection, license plate recognition, all related to artificial
            intelligence (AI). These models learn from data, and make predictions or decisions based on
            learning.</Typography>,
    },
    {
        question: <Typography variant='h6'>7 - Why do we need to update the AI model?</Typography>,
        answer: <>
            <Typography variant='body1'>We release new AI models for the following reasons:</Typography>
            <Typography variant='body1' gutterBottom component="div">
                <ul>
                    <li>Improve accuracy and performance to adapt to changing data over time</li>
                    <li>Adapt to changing environments incurred by concept drift or seasonal variations</li>
                    <li>Address biases and fairnesses</li>
                    <li>Enhance features and functionality</li>
                    <li>Regulatory compliance and security enhancements</li>
                    <li>Ensure the relevance and effectiveness of models in providing insights or predictions
                        in fast-moving fields
                    </li>
                    <li>User feedback</li>
                </ul>
            </Typography>
        </>,
    },
    {
        question: <Typography variant='h6'>8 - How to update AI model for multiple cameras?</Typography>,
        answer: <>
            <Typography variant='body1' gutterBottom>Go to <span style={{fontWeight: 'bold'}}>Cameras</span>, select the
                cameras you would like to update, then click <span style={{fontWeight: 'bold'}}>MODEL UPDATE</span> in
                the top left.</Typography>
            <img src={modelUpdate} alt='model-update' style={{width: '70%', margin: 8}}/>
        </>,
    },
    {
        question: <Typography variant='h6'>9 - What is an Application?</Typography>,
        answer: <Typography variant='body1' gutterBottom>An Application refers to the program used to update our
            software systems, such as webserver, camera diagnosis, etc.</Typography>,
    },
    {
        question: <Typography variant='h6'>10 - How to add a new camera?</Typography>,
        answer: <>
            <Typography variant='body1' gutterBottom>
                Go to <span style={{fontWeight: 'bold'}}>Cameras</span>, click <span
                style={{fontWeight: 'bold'}}>NEW CAMERA</span>. Enter the information according to actual environment,
                and click <span style={{fontWeight: 'bold'}}>SUBMIT</span>.
            </Typography>
            <img src={addNewCamera} alt='add-new-camera' style={{width: '70%', margin: 8}}/>
            <img src={cameraDetail} alt='camera-detail' style={{width: '70%', margin: 8}}/>
            <Typography variant='body1' gutterBottom>
                You can also add new Cameras with Unit creation, by clicking the <span
                style={{fontWeight: 'bold'}}>+NEW</span> button next to Camera selection.
            </Typography>
            <img src={addRelatedModel} alt='add-related-model' style={{width: '70%', margin: 8}}/>
        </>,
    },
    {
        question: <Typography variant='h6'>11 - How to add related additional information?</Typography>,
        answer: <>
            <Typography variant='body1' sx={{display: 'flex', alignItems: 'center'}}>For adding existing relations,
                click <ArrowDropDownIcon/>, and select an existing model from the dropdown menu.</Typography>
            <img src={selectRelatedModel} alt='select-related-model' style={{width: '70%', margin: 8}}/>
            <Typography variant='body1' gutterBottom>For adding new models to the relation, use the <span
                style={{fontWeight: 'bold'}}>+NEW</span> button next to relation selection.</Typography>
            <img src={addRelatedModel} alt='add-related-model' style={{width: '70%', margin: 8}}/>
        </>,
    },
    {
        question: <Typography variant='h6'>12 - How to add a new unit?</Typography>,
        answer: <>
            <Typography variant='body1' gutterBottom>Go to <span style={{fontWeight: 'bold'}}>Units</span>, click <span
                style={{fontWeight: 'bold'}}>NEW UNIT</span>. Enter the information according to actual environment, and
                click <span style={{fontWeight: 'bold'}}>SUBMIT</span>.</Typography>
            <img src={addNewUnit} alt='add-new-unit' style={{width: '70%', margin: 8}}/>
            <img src={unitDetail} alt='add-unit-detail' style={{width: '70%', margin: 8}}/>
        </>,
    },
    {
        question: <Typography variant='h6'>13 - Unable to change the status of a unit?</Typography>,
        answer: <>
            <Typography variant='body1' gutterBottom>On unit detail page, unit status can be edited only when the unit
                has synced with the corresponding physical device. </Typography>
            <Typography variant='body1' gutterBottom>If the device manager has never synced with the physical device
                (no <span style={{fontWeight: 'bold'}}>Last Sync Time</span>), the unit status can not be changed away
                from <span style={{fontWeight: 'bold'}}>Never Connected</span>.
            </Typography>
            <Typography variant='body1' gutterBottom>If the device manager detects that the device has not been synced
                for a while, the unit status will automatically be changed
                to <span style={{fontWeight: 'bold'}}>WiFi connection issue</span>, and cannot be changed manually to
                anything else until synced again.
            </Typography>
        </>,
    },
]