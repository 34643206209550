import React, {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useLocation, useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from "@mui/material/Button";
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import {useTheme} from "@mui/material";

export const LoadingComponent = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 10
            }}
        >
            <CircularProgress/>
            <Typography sx={{mt: 2, color: 'grey'}}>Loading... </Typography>
        </Box>
    );
};

export const ErrorComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let from;

    location.state?.from ? from = location.state.from : from = {pathname: "/home"};

    useEffect(() => {
        if (from.pathname === "/home") {
            navigate("/");
        }
    }, [from]);
    const handleClick = () => {
        navigate(from);
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <ErrorOutlineIcon color="error" style={{fontSize: 80}}/>
            <Typography variant="h5" color="error" sx={{mt: 2}}>
                Error
            </Typography>
            <Typography variant="h6" sx={{mt: 2}}>
                Oops, something went wrong. Please try again or contact support team at: XXX@xxx.com
            </Typography>
            <Button onClick={handleClick}><ArrowBackIcon style={{fontSize: 60}}/></Button>
        </Box>
    );
};

export const NoDataComponent = ({message = 'No data available'}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="200px"
        >
            <ReportOutlinedIcon style={{fontSize: 45, marginBottom: theme.spacing(2), color: 'primary'}}/>
            <Typography variant="h6">{message}</Typography>
        </Box>
    );
};