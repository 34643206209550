import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {BASE_URL, useVerifyCode} from "../routes/api";
import Cookies from "js-cookie";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {Alert, AlertTitle, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {LoadingComponent} from "../util/loading";
import {EnumContext} from "../components/enumContext";
import LILogo from "../assets/logo/li_logo_small.png";
import Avatar from "@mui/material/Avatar";
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';

export default function ValidateEmail() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            navigate('/home');
        }
    }, [navigate]);

    const username = Cookies.get('username');

    const {data, loading, error, statusCode, handleCall} = useVerifyCode();
    const [formData, setFormData] = useState({username: username, code: null});
    const [alert, setAlert] = useState(false);
    const {updateEnums} = useContext(EnumContext);

    const handleChange = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleCall({body: formData});
        setFormData({username: username, code: null});

    };

    useEffect(() => {
        if (statusCode === 422) {
            setAlert(true);
        }
    }, [statusCode]);

    useEffect(() => {
        const fetchEnums = async () => {
            const response = await fetch(
                `${BASE_URL}/getenums/`,
                {
                    headers: {
                        'Content-Type': 'application/json', 'Authorization': `Token ${data.token}`,
                    }
                }
            );
            if (response.ok) {
                updateEnums(await response.json());
            }
        }
        if (data && data.token && Object.keys(data).includes('can_create')) {
            Cookies.set('token', data.token, {expires: 1});
            Cookies.set('can_create', data.can_create, {expires: 1});
            fetchEnums();
            navigate("/home");
        }
    }, [data]);

    if (loading) return <LoadingComponent/>

    return <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Collapse in={alert}>
            <Alert severity="error"
                   action={<IconButton
                       aria-label="close"
                       color="inherit"
                       size="small"
                       onClick={() => {
                           setAlert(false);
                       }}
                   >
                       <CloseIcon fontSize="inherit"/>
                   </IconButton>}
                   sx={{mb: 2}}
            >
                <AlertTitle>Error</AlertTitle>
                Verification code incorrect, please try again
            </Alert>
        </Collapse>
        <Box
            sx={{
                marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
        >
            <img src={LILogo} alt='Leopard Imaging' style={{width: '50%'}}/>
            <Typography component="h1" variant="h5" color="#535353" sx={{mb: 3, mt: 1, fontWeight: 'bold'}}>
                Device Manager
            </Typography>
            <Avatar sx={{m: 1, bgcolor: 'primary.light'}}>
                <TaskAltRoundedIcon/>
            </Avatar>
            <Typography component="h2" variant="h6" color="textPrimary">
                Email Verification Code
            </Typography>
            <Box component="form"
                 onSubmit={handleSubmit}
                 noValidate
                 sx={{
                     mt: 1,
                     minWidth: '400px',
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="code"
                    label="Code"
                    type="code"
                    id="code"
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!formData.code}
                    sx={{mt: 3, mb: 2}}
                >
                    Verify
                </Button>
            </Box>
        </Box>
    </Container>
}