import Cookies from "js-cookie";
import {routes, usePostData} from "../routes/api";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Divider, Grid, Typography} from "@mui/material";
import {cameraFields} from "../util/modelFields";
import {InputField} from "../components/inputField";
import Button from "@mui/material/Button";
import Properties from "../components/property";
import {LoadingComponent} from "../util/loading";
import {getCreateFields, getRequiredFields, isDict} from "../util/util";
import RelationView from "../components/relationView";
import ErrorDisplay from "../components/errorDisplay";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";

export function CameraCreate({setId, navigation, setClose}) {
    const token = Cookies.get('token');
    const cameraRoute = routes.camera;
    const requiredFields = getRequiredFields(cameraFields.fields);
    const fields = getCreateFields(cameraFields.fields);

    const [cameraFormdata, setCameraFormdata] = useState(Object.values(cameraFields.fields).filter(item => item.defaultValue).reduce((acc, item) => ({
        ...acc,
        [item.alias]: item.defaultValue
    }), {}));
    const [cameraProperties, setCameraProperties] = useState({});
    const [touched, setTouched] = useState({});
    const [error, setError] = useState(false);
    const [propertyError, setPropertyError] = useState(false);
    const [fieldChecks, setFieldChecks] = useState({});
    const [alert, setAlert] = useState(false);
    const [errorFields, setErrorFields] = useState([]);

    const {
        data: cameraPostResp,
        loading: cameraLoading,
        statusCode: cameraPostStatus,
        error: cameraError,
        handleCall: postCamera
    } = usePostData(token, cameraRoute);


    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from ? location.state.from : {pathname: "/camera"};

    const handleCameraChange = (e, regexp) => {
        setCameraFormdata({
            ...cameraFormdata,
            [e.target.name]: e.target.value
        });
        if (regexp) {
            setFieldChecks({
                ...fieldChecks,
                [e.target.name]: !regexp.test(e.target.value)
            });
        }

        setErrorFields(errorFields.filter(item => item !== e.target.name));
    }

    const handleEnumChange = (e) => {
        setCameraFormdata({
            ...cameraFormdata,
            [e.target.name]: e.target.value
        })
    }
    const handleDateChange = (key, value) => {
        setCameraFormdata({
            ...cameraFormdata,
            [key]: value.format('YYYY-MM-DD')
        });
    }

    const onBlur = (e) => {
        setTouched({...touched, [e.target.name]: true});
    }

    const handleSubmitCamera = () => {
        postCamera({
            body: {
                ...cameraFormdata,
                new_property: Object.values(cameraProperties)
            }
        });
    }

    useEffect(() => {
        const regexError = Object.values(fieldChecks).reduce((acc, item, _) => (acc || item), false);
        setError(requiredFields.reduce((acc, item, _) => (acc || (!cameraFormdata[item])), false) || propertyError || regexError);
    }, [requiredFields, cameraFormdata, propertyError, fieldChecks])


    useEffect(() => {
        if (cameraPostStatus >= 200 && cameraPostStatus < 300) {
            setAlert(false);
            if (setId) {
                setId(cameraPostResp.id)
            }
            if (navigation) {
                navigate(from);
            }
            if (setClose) {
                setClose();
            }
        } else if (cameraPostStatus > 300) {
            setAlert(true);
            setErrorFields(isDict(cameraPostResp) ? Object.keys(cameraPostResp) : []);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [cameraPostStatus, cameraPostResp]);


    const handleRelationChange = (e, newValue, key, multiple) => {
        if (multiple) {
            setCameraFormdata({
                ...cameraFormdata,
                [key]: newValue.map((item) => item[0])
            });
        } else if (!newValue) {
            const tempForm = {...cameraFormdata};
            delete tempForm[key];
            setCameraFormdata(tempForm);
        } else {
            setCameraFormdata({
                ...cameraFormdata,
                [key]: newValue[0]
            });
        }
    }

    if (cameraLoading) return <LoadingComponent/>

    return (
        <Grid container spacing={2} alignItems="baseline" justifyContent="flex-start"
              sx={{margin: 'auto', mt: 5, width: '80%'}}>
            <Grid item xs={12}>
                <ErrorDisplay
                    alert={alert}
                    setAlert={setAlert}
                    response={cameraPostResp}
                    fields={cameraFields}
                    statusCode={cameraPostStatus}
                />
            </Grid>
            {navigation && <Grid item xs={12}>
                <NavigateBreadcrumbs alias='camera' display='Camera' create/>
            </Grid>}
            <Grid item xs={12} sx={{mt: 5}}>
                <Typography variant='h4'>New Camera</Typography>
            </Grid>
            {fields.map((field) => (
                <Grid item xs={12} md={6} xl={3}
                      sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                    <InputField field={field}
                                error={errorFields.includes(field.alias)}
                                onChange={handleCameraChange}
                                onDateChange={handleDateChange}
                                onEnumChange={handleEnumChange}
                                onBlur={onBlur}
                                value={cameraFormdata[field.alias]}
                                regexCheck={fieldChecks}
                    />
                </Grid>
            ))}
            <Grid item xs={12} sx={{mt: 3}}>
                <Typography variant='h5'>Additional Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <RelationView
                    alias='camera'
                    handleChange={handleRelationChange}
                    currentValue={cameraFormdata}
                />
            </Grid>
            <Grid item xs={12}>
                <Properties
                    display="Camera"
                    setData={setCameraProperties}
                    data={cameraProperties}
                    setError={setPropertyError}
                    prefix="cp"
                />
            </Grid>
            <Grid item xs={12} sx={{mt: 10}}>
                <Button variant="contained" onClick={handleSubmitCamera} disabled={error}>Submit</Button>
            </Grid>
        </Grid>
    )
}