export const boolSearchFields = [
    'u_wifi',
    'is_active',
    'is_staff',
    'u_cam_error',
]

export const enumSearchFields = [
    'u_status',
    'c_status',
    'u_error',
    'c_error'
]

export const dateSearchFields = [
    'last_updated_date',
    'u_timeAdded',
    'c_timeAdded',
    'f_timeAdded',
    'am_timeAdded',
    'app_timeAdded',
    'u_timeLastSynced',
    'c_timeLastSynced',
    'f_buildDate',
    'am_buildDate',
    'app_buildDate',
]

export const cameraSearchFields = {
    c_sn: 'Serial No.',
    c_sensor: 'Sensor SN',
    c_status: 'Status',
    c_part: 'Part No.',
    c_model: 'AI Model Name',
    c_property_name: 'Related Info Key',
    c_property_value: 'Related Info Value',
};

export const unitSearchFields = {
    u_sn: 'Serial No.',
    u_status: 'Unit Status',
    u_wifi: 'Wifi Status',
    u_part: 'Part No.',
    u_error: 'Unit Error',
    u_cam_error: 'Camera Status',
    u_sensor: 'Sensor SN',
    // u_camera: 'Attached Camera',
    u_firmware: 'Firmware Name',
    // u_latitude: 'Latitude',
    // u_longitude: 'Longitude',
};

export const AIModelSearchFields = {
    am_name: 'Name',
    am_version: 'Version',
    am_buildDate: 'Built Date',
    am_property_name: 'Related Info Key',
    am_property_value: 'Related Info Value',
};

export const FirmwareSearchFields = {
    f_name: 'Name',
    f_version: 'Version',
    f_buildDate: 'Built Date',
    f_property_name: 'Related Info Key',
    f_property_value: 'Related Info Value',
};

export const ApplicationSearchFields = {
    app_name: 'Name',
    app_version: 'Version',
    app_buildDate: 'Built Date',
    app_property_name: 'Related Info Key',
    app_property_value: 'Related Info Value',
};

export const accountSearchFields = {
    username: 'Username',
    first_name: 'First Name',
    last_name: 'Last Name',
    a_phone: 'Phone No.',
    a_type: 'Account Type',
    is_active: 'Active',
    is_staff: 'Staff',
    a_property_name: 'Related Info Key',
    a_property_value: 'Related Info Value',
};