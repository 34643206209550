import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {FAQList} from "../util/FAQ";
import Typography from "@mui/material/Typography";
import React from "react";


export default function FAQPage() {
    return <Grid
        container
        spacing={2}
        sx={{margin: 'auto', mt: 5, width: '80%'}}
        justifyContent="space-between"
    >
        <Grid item xs={12}/>
        <Grid item xs={12}>
            <NavigateBreadcrumbs alias='help' display='FAQ'/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant='h4'>Frequently Asked Questions</Typography>
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12}>
            {FAQList.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        {faq.question}
                    </AccordionSummary>
                    <AccordionDetails>
                        {faq.answer}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Grid>
    </Grid>
}