import React from "react";
import {IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@mui/material";
import {LightTooltip} from "../util/util";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import {green, red} from "@mui/material/colors";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BrowserNotSupportedOutlinedIcon from '@mui/icons-material/BrowserNotSupportedOutlined';
import Typography from "@mui/material/Typography";

export default function StatusList({data}) {
    if (data.length === 0) {
        return (
            <Typography variant="subtitle1" color="grey"
                        sx={{fontSize: '1.1rem', display: 'flex', alignItems: 'center'}}>
                <IconButton disableRipple>
                    <BrowserNotSupportedOutlinedIcon sx={{fontSize: 30}}/>
                </IconButton>
                No update statuses available
            </Typography>
        )
    }
    return <List
        dense
        sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 180,
        }}
        subheader={<li/>}
    >
        <ListSubheader sx={{fontSize: '1.2rem'}}>Recent Update Status</ListSubheader>
        {data.map((item) => (<ListItem key={`message-${item.id}`} disablePadding divider>
                <ListItemIcon>
                    <LightTooltip title={item.update_status ? 'Successful' : 'Failed'}>
                        {item.update_status ? <CheckCircleOutlinedIcon style={{color: green[300]}}/> :
                            <CancelOutlinedIcon style={{color: red[300]}}/>}
                    </LightTooltip>
                </ListItemIcon>
                <ListItemText primary={item.timestamp} sx={{minWidth: 150, maxWidth: 150}}/>
                <ListItemText
                    primary={`Message: ${item.message ? item.message : 'N/A'}`}
                />
        </ListItem>))}
    </List>
}