import Cookies from "js-cookie";
import {fileUrl, routes, useGetDataById, useRefreshData} from "../routes/api";
import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {LoadingComponent, NoDataComponent} from "../util/loading";
import {
    Alert,
    AlertTitle,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    Grid,
    IconButton
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    OverflowTooltip,
    sleep,
    toHumanReadableTime,
    updateConfirmationMessage,
    updateData,
    updateTime
} from "../util/util";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';
import HideImageIcon from '@mui/icons-material/HideImage';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import {ImagePopUpModal} from "../components/image";
import EditIcon from "@mui/icons-material/Edit";
import {AIModelRelationHeaders, CameraHeaders} from "../util/getFields";
import {ContentDetail} from "../components/contentDetail";
import {PopupDialog} from "../components/popupDialog";
import {RelationEdit} from "../components/relationEdit";
import {aiModelFields, cameraFields} from "../util/modelFields";
import {DeleteComponent} from "../components/confirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import ErrorDisplay from "../components/errorDisplay";
import {grey} from "@mui/material/colors";
import StatusList from "../components/statusList";
import WaitTimeStatus from "../components/waitTimeStatus";
import {EnumContext} from "../components/enumContext";

const filterTime = (obj, timeDiff) => {
    if (obj.time) {
        const now = new Date();
        const timestamp = new Date(obj.time);
        return (now - timestamp) / 1000 <= timeDiff
    }
    return false
}


export function CameraDetail() {
    const token = Cookies.get('token');
    const canCreate = Cookies.get('can_create') === 'true';
    const {id} = useParams();
    const route = routes.camera;
    const {enums} = useContext(EnumContext);

    const [snackbarList, setSnackbarList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newData, setNewData] = useState(null);
    const [noData, setNoData] = useState(false);
    const [noRecord, setNoRecord] = useState(false);
    const [noImage, setNoImage] = useState(false);
    const [timeDiff, setTimeDiff] = useState(60);
    const [refreshing, setRefreshing] = useState(false);
    const [refreshError, setRefreshError] = useState(false);
    const [deleteError, setDeleteError] = useState({error: false, message: null, status: null});
    const [modalOpen, setModalOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [popup, setPopup] = useState({
        'aimodel': false,
        'cameraproperty': false
    })

    const {data: detail, statusCode: getStatus, handleCall} = useGetDataById(token, route, id);
    const {statusCode: refreshStatus, handleCall: refreshCall} = useRefreshData(token, route, id);

    const navigate = useNavigate();
    const location = useLocation();

    const unitId = location.state?.unitId ? location.state.unitId : null;
    const unitSN = location.state?.unitSN ? location.state.unitSN : null;
    const page = location.state?.page ? location.state.page : null;

    useEffect(() => {
        if (getStatus > 400) {
            navigate('/camera');
        }
    }, [getStatus]);

    const handleSliderChange = (event, newValue) => {
        setTimeDiff(newValue);
    };

    const addSnackbar = (field, model, time) => {
        const id = new Date().getTime();
        setSnackbarList((prevSnackbars) => [
            ...prevSnackbars,
            {id, field, model, time},
        ]);
    };

    const handleClose = (id, event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarList((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.id !== id));
    };

    const handleAIModelClick = (aimodel) => {
        navigate(`/aimodel/${aimodel}`, {
            state: {
                from: location,
                unitSN: unitSN,
                unitId: unitId,
                cameraSN: newData.data.c_sn,
                cameraId: id
            }
        });
    }

    const handleModal = () => {
        setModalOpen((m) => !m);
    }

    const handleRefresh = async () => {
        try {
            setRefreshing(true);
            await refreshCall({body: {type: 'IMG'}});
            if (refreshStatus < 300) {
                await sleep(4000);
                addSnackbar('Refreshing', 'Camera', '2 minutes')
                await handleCall();
            } else {
                setRefreshError(true);
            }
            setRefreshing(false);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         handleRefresh();
    //     }, 1800000);
    //
    //     return () => clearInterval(interval);
    // }, []);

    const fetchDetail = async () => {
        try {
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handlePopupClose = (key) => {
        setPopup({...popup, [key]: false});
    }

    useEffect(() => {
        setLoading(true);
        fetchDetail();
    }, []);

    useEffect(() => {
        if (JSON.stringify(detail) !== '{}') {
            const updatedRecords = detail.records ? detail.records.filter((record) => (record.time && filterTime(record, timeDiff))).sort((a, b) => new Date(a.time) - new Date(b.time)).map((record) => updateTime(record)) : [];
            const updatedData = updateData(detail.data, CameraHeaders, 'c', enums);
            const updatedAIModel = updateData(detail.AIModel, AIModelRelationHeaders, 'am',);
            let updatedConfirmationMessage = updateConfirmationMessage(detail.ConfirmationMessage);
            setNewData({
                ...detail,
                records: updatedRecords,
                data: updatedData,
                AIModel: updatedAIModel,
                ConfirmationMessage: updatedConfirmationMessage,
            });
            setLoading(false);
            (updatedData) ? setNoData(false) : setNoData(true);
            (updatedRecords && updatedRecords.length > 0) ? setNoRecord(false) : setNoRecord(true);
            (detail.images && detail.images.length > 0 && detail.images[0].file) ? setNoImage(false) : setNoImage(true);


        }
    }, [detail, timeDiff]);

    if (loading) return <LoadingComponent/>
    if (noData) return <NoDataComponent/>

    return <Grid container spacing={2} alignItems="stretch" justifyContent="center"
                 sx={{margin: 'auto', mt: 5, width: '80%'}}>
        <Grid item xs={12}>
            <Collapse in={refreshError}>
                <Alert severity="error"
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setRefreshError(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                       sx={{mb: 2}}
                >
                    <AlertTitle>Error</AlertTitle>
                    Couldn't refresh at the time. Please try again later.
                </Alert>
            </Collapse>
            <ErrorDisplay
                fields={cameraFields}
                alert={deleteError.error}
                setAlert={(alert) => setDeleteError({...deleteError, error: alert})}
                response={deleteError.message}
                statusCode={deleteError.status}
            />
            <WaitTimeStatus
                snackbarList={snackbarList}
                handleClose={handleClose}
            />
        </Grid>
        <Grid item xs={12}>
            <NavigateBreadcrumbs alias='camera' display='Camera' id={newData.data.c_sn} unitId={unitId}
                                 unitSN={unitSN} page={page}/>
        </Grid>
        <Grid item xs={12} sx={{mb: 3}}>
            <Typography variant='h4'>Camera Detail</Typography>
        </Grid>
        <Grid item xs={4} sm={3} lg={2} sx={{mb: 3}}>
            <DeleteComponent
                alias='camera'
                id={id}
                navigate={navigate}
                setError={setDeleteError}
            />
        </Grid>
        <Grid item xs={4} sm={6} lg={8}/>
        <Grid item xs={4} sm={3} lg={2} key="refresh" sx={{mb: 5, display: "flex", justifyContent: "flex-end"}}>
            <Button variant="contained" onClick={handleRefresh} startIcon={<RefreshIcon/>}>Refresh</Button>
        </Grid>
        {refreshing &&
            [
                <Grid item xs={5}/>,
                <Grid item xs={2}>
                    <CircularProgress/>
                </Grid>,
                <Grid item xs={5}/>

            ]
        }

        <Grid item xs={12} key="camera-detail" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={canCreate ?
                        <IconButton onClick={() => setEdit(!edit)}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='Details'
                />
                <CardContent>
                    <ContentDetail
                        alias='camera'
                        id={id}
                        data={newData.data}
                        edit={edit}
                        setEdit={setEdit}
                        refresh={fetchDetail}
                        fields={cameraFields}
                    />
                    <StatusList data={newData.ConfirmationMessage}/>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} key="properties" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={canCreate ?
                        <IconButton onClick={() => (setPopup({...popup, cameraproperty: true}))}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title="Additional Information"/>
                {newData.CameraProperty.length === 0 ?
                    <CardContent>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No additional information
                        </Typography>
                    </CardContent> :
                    <CardContent>
                        <Grid container
                              spacing={1}
                              sx={{margin: 'auto', mb: 2}}
                              alignItems="center"
                              justifyContent="center"
                        >
                            {newData.CameraProperty.map(([id, [key, value, desc]]) => (
                                [
                                    <Grid item xs={5} key={key} sx={{mb: 1}}>
                                        <Tooltip title={desc} arrow>
                                            <Typography variant="body1" color={grey[700]}>
                                                {key}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>,
                                    <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                        <Tooltip title={desc} arrow placement="bottom-start">
                                            <Typography variant="body1">
                                                {value}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                ]
                            ))}
                        </Grid>
                    </CardContent>}
            </Card>
        </Grid>
        <Grid item xs={12} lg={6} key="camera-model" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={canCreate ?
                        <IconButton onClick={() => (setPopup({...popup, aimodel: true}))}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='AI Model'
                />
                {Object.keys(newData.AIModel).length === 0 ?
                    <CardContent>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No Related AI Model
                        </Typography>
                    </CardContent> :
                    <CardContent>
                        <CardActionArea onClick={() => handleAIModelClick(detail.AIModel.id)}>
                            <Grid container
                                  spacing={1}
                                  sx={{margin: 'auto', mb: 2}}
                                  alignItems="center"
                                  justifyContent="center"
                            >
                                {Object.entries(newData.AIModel).filter((item) => (item[0] !== 'id')).map(([key, value]) => (
                                    [
                                        <Grid item xs={5} key={key} sx={{mb: 1}}>
                                            <Typography variant="body1" color={grey[700]}>
                                                {aiModelFields.fields[key].display || key}
                                            </Typography>
                                        </Grid>,
                                        <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                            <OverflowTooltip title={value} followCursor>
                                                <Typography variant="body1">
                                                    {value}
                                                </Typography>
                                            </OverflowTooltip>
                                        </Grid>
                                    ]
                                ))}
                            </Grid>
                        </CardActionArea>
                    </CardContent>
                }
            </Card>
        </Grid>
        {/*<Grid item sm={12} md={6} key="chart" sx={{mt: 2, height: 400}}>*/}
        {/*    <Typography variant="h5">Chart</Typography>*/}
        {/*    <Grid container spacing={2} alignitems="center" sx={{mt: 4, mb: 5}}>*/}
        {/*        <Grid item>*/}
        {/*            <Typography variant="overline" gutterBottom>Time Range(second)</Typography>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={6}>*/}
        {/*            <Slider*/}
        {/*                value={typeof timeDiff === 'number' ? timeDiff : 60}*/}
        {/*                onChange={handleSliderChange}*/}
        {/*                defaultValue={60}*/}
        {/*                min={20}*/}
        {/*                max={120}*/}
        {/*                aria-labelledby="input-slider"*/}
        {/*                valueLabelDisplay="auto"*/}
        {/*            />*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*    {noRecord ? <Typography variant="button">*/}
        {/*            No Data Found*/}
        {/*        </Typography>*/}
        {/*        : <RenderChart data={newData.records} dataKey={[dataKey]}/>}*/}
        {/*</Grid>*/}
        <PopupDialog
            open={popup.cameraproperty}
            content={<RelationEdit
                fields={cameraFields}
                alias='camera'
                display='Camera'
                id={id}
                relation='CameraProperty'
                refresh={fetchDetail}
                addSnackbar={addSnackbar}
                defaultValue={newData.CameraProperty.map(([id, [key, value]]) => ([id, `${key}: ${value}`]))}
                setEdit={(value) => (setPopup({...popup, cameraproperty: value}))}
            />}
            handleClose={() => handlePopupClose('cameraproperty')}
        />
        <PopupDialog
            open={popup.aimodel}
            content={<RelationEdit
                fields={cameraFields}
                alias='camera'
                display='Camera'
                id={id}
                relation='AIModel'
                refresh={fetchDetail}
                addSnackbar={addSnackbar}
                defaultValue={
                    Object.keys(newData.AIModel).length === 0 ?
                        null :
                        [newData.AIModel.id, `${newData.AIModel.am_name} (${newData.AIModel.am_version})`]
                }
                setEdit={(value) => (setPopup({...popup, aimodel: value}))}
            />}
            handleClose={() => handlePopupClose('aimodel')}
        />

        <Grid item xs={12} lg={6} key="image">
            {noImage ?
                <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                    <CardHeader title="Recent Image"/>
                    <CardContent>
                        <IconButton disableRipple>
                            <HideImageIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            Image not Available
                        </Typography>
                    </CardContent>
                </Card>
                : [
                    <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                        <CardHeader title="Recent Image"/>
                        <CardContent>
                            <Grid container
                                  spacing={1}
                                  sx={{margin: 'auto'}}
                                  alignItems="center"
                                  justifyContent="center"
                            >
                                <Grid item xs={3} key='refresh-key'>
                                    <Typography variant="body1" color={grey[700]}>
                                        Time Taken
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} key='refresh-value'>
                                    <Typography variant="body1">
                                        {newData.images[0] ? toHumanReadableTime(newData.images[0].time).datetimeStr : "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActionArea onClick={handleModal} sx={{mb: 2}}>
                            <CardMedia
                                component="img"
                                image={fileUrl(newData.images[0].file)}
                                alt={newData.images[0].time}
                            />
                        </CardActionArea>
                    </Card>,
                    <ImagePopUpModal
                        img={fileUrl(newData.images[0].file)}
                        open={modalOpen}
                        setOpen={setModalOpen}
                        currentPoints={detail.data.c_coordinates}
                        id={id}
                        addSnackbar={addSnackbar}
                    />
                ]
            }
        </Grid>
    </Grid>
}