import Cookies from "js-cookie";
import {routes, useGetDataList, useSearch} from "../routes/api";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AccountListHeaders} from "../util/getFields";
import {LoadingComponent, NoDataComponent} from "../util/loading";
import {Grid, MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import SearchBox from "../components/searchBox";
import {accountSearchFields} from "../util/searchFields";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {PAGE_SIZE} from "../util/util";
import {accountFields, userFields} from "../util/modelFields";

export function AccountList() {
    const token = Cookies.get('token');
    const canCreate = Cookies.get('can_create') === 'true';
    const route = routes.account;
    const allFields = {...accountFields.fields, ...userFields.fields};

    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);
    const [viewData, setViewData] = useState(null);
    const viewHeaders = AccountListHeaders.filter(key => key !== 'id').map((key) =>
        ({
            field: key,
            headerName: allFields[key].display,
            type: allFields[key].type === 'boolean' ? 'boolean' : 'string',
            minWidth: 100,
            flex: 1,
            valueGetter: (value) => (
                typeof value === 'boolean' ?
                    (value ?
                        'Yes' :
                        'No')
                    : (Array.isArray(value) ?
                        value.reduce((acc, item) => (acc ? `${acc}, ${item}` : item), '') :
                        value)
            )
        }));

    const [searchParams, setSearchingParams] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [searching, setSearching] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [totalPages, setTotalPages] = useState(null);

    const {data, statusCode: status, handleCall} = useGetDataList(token, route);
    const {data: searchData, statusCode: searchStatus, handleCall: searchCall} = useSearch(token, route);


    const navigate = useNavigate();
    const location = useLocation();

    const page = location.state?.page ? location.state.page : null;
    const histPage = window.history.state?.page ? window.history.state.page : null;

    const [paginationModel, setPaginationModel] = useState({
        page: histPage || page || 0,
        pageSize: PAGE_SIZE,
    });

    const fetchData = async () => {
        try {
            setLoading(true);
            if (searching) {
                await searchCall({params: {...searchParams, page: paginationModel.page + 1}});
            } else {
                await handleCall({params: {page: paginationModel.page + 1}});
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [paginationModel]);

    useEffect(() => {
        if (status === 204 || data?.count === 0 || data?.results?.length === 0) {
            setNoData(true);
            setLoading(false);
        } else if (data?.results?.length > 0) {
            setViewData(data.results.map((item) => ({...item, ...item.a_user, id: item.id})));
            setNoData(false);
            setLoading(false);
            setTotalPages(Math.ceil(data.count / PAGE_SIZE));
        } else {
            setNoData(false);
            setLoading(true);
        }
    }, [data, status]);

    useEffect(() => {
        if (searchStatus === 204 || searchData?.count === 0 || searchData?.results?.length === 0) {
            setLoading(false);
        } else if (searchData.results?.length > 0) {
            setFilteredData(searchData.results.map((item) => ({...item.a_user, id: item.id})));
            setLoading(false);
            setTotalPages(Math.ceil(searchData.count / PAGE_SIZE));
        } else {
            setLoading(true);
        }
    }, [searchData, searchStatus]);

    const handleRowClick = (param, event) => {
        event.preventDefault();
        window.history.replaceState({from: location, page: paginationModel.page}, '');
        navigate(`/account/${param.row.id}`, {state: {from: location, page: paginationModel.page}});
    };
    const handleEntry = (event) => {
        event.preventDefault();
        navigate(`/account/new`, {state: {from: location, page: paginationModel.page}});
    };

    const handleSearch = async () => {
        setLoading(true);
        setFilteredData({});
        setSearching(true);
        setPaginationModel((pm) => ({...pm, page: 0}));
    }

    const handleOpenSearch = () => {
        setOpenSearch(true);
    }

    const handleCancelSearch = () => {
        if (searching) {
            setPaginationModel((pm) => ({...pm, page: 0}));
        }
        setOpenSearch(false);
        setSearching(false);
        setFilteredData({});
    }

    const handlePageSelect = (e) => {
        setPaginationModel((pm) => ({...pm, page: e.target.value}));
    }

    if (loading) return <LoadingComponent/>
    if (noData) return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justify="center"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='account' display='Account' page={page}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h4'>Accounts</Typography>
            </Grid>
            {canCreate &&
                <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button component="label"
                            variant="contained"
                            onClick={handleEntry}
                            startIcon={<AddIcon/>}
                    >
                        New Account
                    </Button>
                </Grid>}
            <Grid item xs={12}>
                <NoDataComponent/>
            </Grid>
        </Grid>
    )

    return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justifyContent="center"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='account' display='Account' page={page}/>
            </Grid>
            <Grid item xs={9}>
                <Typography variant='h4'>Accounts</Typography>
            </Grid>
            {canCreate && <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    variant="contained"
                    onClick={handleEntry}
                    startIcon={<AddIcon/>}
                >
                    New Account
                </Button>
            </Grid>}
            <Grid item xs={9}/>
            <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    onClick={openSearch ? handleCancelSearch : handleOpenSearch}
                    startIcon={<ManageSearchIcon/>}
                    sx={{mb: 1}}>
                    {openSearch ? "Cancel" : "Search"}
                </Button>
            </Grid>
            {openSearch && <SearchBox
                prefix='a'
                searchParams={searchParams}
                setSearchParams={setSearchingParams}
                searchFields={accountSearchFields}
                sendSearch={handleSearch}
            />}
            <Grid item xs={12}/>
            {searching && <Grid item xs={12}>
                <Typography variant='h6'>
                    Filtered Results:
                </Typography>
            </Grid>}
            <Grid item xs={12}>
                <DataGrid
                    disableColumnFilter
                    columns={viewHeaders}
                    rows={searching ? filteredData : viewData}
                    rowCount={(searching ? searchData.count : data.count) || 0}
                    paginationMode="server"
                    slots={{toolbar: GridToolbar}}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[PAGE_SIZE]}
                    autoHeight={true}
                    rowHeight={80}
                    onRowClick={handleRowClick}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                a_phone: false,
                                a_type: false,
                                is_staff: false,
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end", alignItems: 'center'}}>
                <Typography sx={{mr: 1}}>Page: </Typography>
                <Select
                    name='Page'
                    key='page'
                    onChange={handlePageSelect}
                    value={paginationModel.page}
                    sx={{minWidth: 70}}
                >
                    {Array.from({length: totalPages}, (_, index) => (
                        <MenuItem key={index} value={index}>
                            {index + 1}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    )
}