import {styled, tooltipClasses} from "@mui/material";
import {CameraCreate} from "../pages/cameraCreate";
import FileCreate from "../pages/fileCreate";
import {AIModelHeaders, ApplicationHeaders, FirmwareHeaders} from "./getFields";
import {aiModelFields, applicationFields, cameraFields, firmwareFields} from "./modelFields";
import {AIModelSearchFields, ApplicationSearchFields, FirmwareSearchFields} from "./searchFields";
import React, {useEffect, useRef, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Check, Close} from "@mui/icons-material";

// Configure Day.js to use UTC and Timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const PAGE_SIZE = 20;

export const updateData = (obj, headers, prefix, enums) => {
    if (Object.keys(obj).length === 0) {
        return obj;
    }

    let newObj = Object.keys(obj).reduce((acc, key) => {
        if (!headers || headers.includes(key)) {
            if (enums && Object.keys(enums).includes(key)) {
                if (Array.isArray(obj[key])) {
                    acc[key] = obj[key].map((item) => (enums[key].data[item] || item));
                } else {
                    acc[key] = enums[key].data[obj[key]];
                }
            } else {
                acc[key] = obj[key];
            }
        }

        return acc;
    }, {})

    if (newObj[`${prefix}_content`]) {
        newObj[`${prefix}_content`] = newObj[`${prefix}_content`].split('/').pop();
    }

    if (newObj[`${prefix}_timeAdded`]) {
        const {datetimeStr} = toHumanReadableTime(newObj[`${prefix}_timeAdded`])
        newObj[`${prefix}_timeAdded`] = datetimeStr;
    }
    if (newObj[`${prefix}_timeLastSynced`]) {
        const {datetimeStr} = toHumanReadableTime(newObj[`${prefix}_timeLastSynced`])
        newObj[`${prefix}_timeLastSynced`] = datetimeStr;
    }

    if (newObj[`last_update_time`]) {
        const {datetimeStr} = toHumanReadableTime(newObj.last_update_time)
        newObj.last_update_time = datetimeStr;
    }

    if (newObj.creator) {
        delete newObj.creator;
    }

    return newObj;
}

export const updateConfirmationMessage = (objList) => {
    let newObjList = objList.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp);
    })
    newObjList = newObjList.map((obj) => {
        let newObj = {...obj};
        if (newObj.timestamp) {
            const {datetimeStr} = toHumanReadableTime(newObj.timestamp)
            newObj = {...newObj, timestamp: datetimeStr};
        }
        return newObj;
    })
    return newObjList;
}

export const toHumanReadableTime = (timeString) => {
    if (!timeString) {
        return {timeStr: "N/A", datetimeStr: "N/A"};
    }
    const userTimezone = dayjs.tz.guess();
    const datetimeStr = dayjs.utc(timeString).tz(userTimezone).format('YYYY-MM-DD HH:mm:ss UTCZ');
    const timeStr = dayjs.utc(timeString).tz(userTimezone).format('HH:mm:ss UTCZ');
    return {timeStr, datetimeStr};
};

export const updateTime = (obj) => {
    if (obj.time) {
        const {timeStr, datetimeStr} = toHumanReadableTime(obj.time);
        return {...obj, time: timeStr, datetime: datetimeStr}
    }
    return obj
}

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const getRequiredFields = (fields) => {
    return Object.values(fields).filter(item => item.required).map(item => item.alias)
}

export const getCreateFields = (fields) => {
    return Object.values(fields).filter(item => item.create)
}

export const isDict = (obj) => {
    return typeof obj === 'object' && obj !== null && Object.getPrototypeOf(obj) === Object.prototype;
};

export const convertUnitData = (units, fields) => {
    return units.reduce((acc, item, _) => ({
        ...acc,
        [item.id]: Object.entries(item).reduce((acc, [key, value]) => {
            if (key === 'id') {
                return acc;
            }
            if (key === 'u_cam_error') {
                acc[fields[key].display] =
                    <OverflowTooltip>{value ? 'Camera Error' : 'All Camera Functional'}</OverflowTooltip>;
            } else if (key === 'u_wifi') {
                acc[fields[key].display] = <OverflowTooltip>{value ? 'Online' : 'Offline'}</OverflowTooltip>;
            } else if (typeof value === 'boolean') {
                acc[fields[key].display] = value ? <Check/> : <Close/>;
            } else if (Array.isArray(value)) {
                acc[fields[key].display] =
                    <OverflowTooltip>{value.reduce((str, item) => (str ? `${str}, ${item}` : item), '')}</OverflowTooltip>;
            } else {
                acc[fields[key].display] = <OverflowTooltip>{value}</OverflowTooltip>;
            }
            return acc;
        }, {})
    }), {});
}

export const relationContent = {
    'Camera': (setId, setClose) => <CameraCreate fields={cameraFields} setId={setId} setClose={setClose}/>,
    'Firmware': (setId, setClose) => <FileCreate fields={firmwareFields} alias='firmware' setId={setId}
                                                 setClose={setClose}/>,
    'Application': (setId, setClose) => <FileCreate fields={applicationFields} alias='application'
                                                    setId={setId} setClose={setClose}/>,
    'AIModel': (setId, setClose) => <FileCreate fields={aiModelFields} alias='aimodel' setId={setId}
                                                setClose={setClose}/>,
    'CameraProperty': null,
    'AccountProperty': null
}

export const fileModel = {
    firmware: {
        prefix: 'f',
        propertyPrefix: 'fp',
        display: 'Firmware',
        fileHeaders: FirmwareHeaders,
        fields: firmwareFields,
        searchFields: FirmwareSearchFields,
        property: 'FirmwareProperty'
    },
    aimodel: {
        prefix: 'am',
        propertyPrefix: 'amp',
        display: 'AI Model',
        fileHeaders: AIModelHeaders,
        fields: aiModelFields,
        searchFields: AIModelSearchFields,
        property: 'AIModelProperty'
    },
    application: {
        prefix: 'app',
        propertyPrefix: 'apr',
        display: 'Application',
        fileHeaders: ApplicationHeaders,
        fields: applicationFields,
        searchFields: ApplicationSearchFields,
        property: 'ApplicationProperty'
    }
}

export const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgba(240, 240, 240, 0.90)',
    }, [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(240, 240, 240, 0.90)',
        color: 'rgba(50, 50, 50, 0.90)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const OverflowTooltip = ({children}) => {
    const textRef = useRef(null);
    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            const {current} = textRef;
            if (current) {
                setIsOverflowed(current.scrollWidth > current.clientWidth || current.scrollHeight > current.clientHeight);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [children]);

    return (
        <LightTooltip title={children} disableHoverListener={!isOverflowed} leaveDelay={300} placement="bottom-start">
            <div ref={textRef} style={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical'
            }}>
                {children}
            </div>
        </LightTooltip>);
};